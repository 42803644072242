import { useEffect } from "react";

import { openNav } from "../functions.js";

import "./Home.css";

import small from "../media/small-logo.png";
import bg from "../media/bg1-mobile.jpg";

import HomeJoin from "./HomeJoin.js";
import ShopPopup from "./ShopPopup.js";
import Slideshow from "./Slideshow.js";
import Sidebar from "./Sidebar.js";
import ShopCategory from "./ShopCategory.js";
import HomeLogo from "./HomeLogo.js";

function Home() {
    useEffect(() => {
        document.getElementById("order-button").style.opacity = "0%";
        setTimeout(() => {
            document.getElementById("shoppopup-component").style.left = "50px";
        }, 1000);
    }, []);

    return (
        <div className="home-container" id="main">
            <div style={{ display: "none" }} id="bg">
                <img src={bg} alt="Background" />
            </div>
            <div id="shoppopup-component">
                <ShopPopup />
            </div>
            <div className="menu-button" onClick={openNav}>
                <div id="menu">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        fill="black"
                        className="bi bi-list home-sidebar-toggle"
                        viewBox="0 0 16 16"
                    >
                        <path
                            fillRule="evenodd"
                            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                        />
                    </svg>
                </div>
            </div>
            <div className="elements" id="rest-of-page">
                <div className="element element0 align-middle">
                    <div className="small-logo">
                        <a href="/shop">
                            <img src={small} width="100%" alt="GiggleLeaf" />
                        </a>
                    </div>
                    <div className="home-logo">
                        <HomeLogo />
                    </div>
                    <div className="scroll-down">
                        <a href="#scroll-position">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                className="bi bi-chevron-double-down"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                                <path
                                    fillRule="evenodd"
                                    d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                />
                            </svg>
                        </a>
                    </div>
                </div>

                <div className="element elementA" id="scroll-position">
                    <Slideshow />
                </div>

                <div className="element elementD">
                    <ShopCategory />
                </div>

                <div className="element elementB">
                    <HomeJoin />
                </div>

                <div className="element elementC">
                    <div className="points-heading-container">
                        <div className="points-heading text-center fs-2">GigglePoints</div>
                        <div className="points-tagline text-center fs-4">Shop, Earn & Spend</div>
                    </div>
                    <div className="points-details text-center">Coming soon.</div>
                </div>
            </div>
            <Sidebar />
        </div>
    );
}
export default Home;
