import "./HomeJoin.css";

import { db } from "../firebase.js";

// On submit form
function submitForm(e) {
    e.preventDefault();
    console.log("Form Submitted");
    let fname = document.getElementById("fname").value;
    let lname = document.getElementById("lname").value;
    let email = document.getElementById("email").value;
    let phone = document.getElementById("phone").value;
    if (true) {
        if (fname === "") {
            console.log("First name blank");
            document.getElementById("fname-error").style.display = "block";
            return;
        } else {
            document.getElementById("fname-error").style.display = "none";
        }
        if (lname === "") {
            console.log("Last name blank");
            document.getElementById("lname-error").style.display = "block";
            return;
        } else {
            document.getElementById("lname-error").style.display = "none";
        }
        if (email === "") {
            console.log("Email blank");
            document.getElementById("email-error").style.display = "block";
            return;
        } else {
            document.getElementById("email-error").style.display = "none";
        }
        if (phone === "") {
            console.log("Phone number blank");
            document.getElementById("phone-error").style.display = "block";
            return;
        } else {
            document.getElementById("phone-error").style.display = "none";
        }
    }

    // if (fname != "" && lname != "" && email != "" && phone != "")
    //     console.log(fname + " " + lname + " " + email + " " + phone);

    // Save customer's input
    customerEntry(fname, lname, email, phone);
}

// Function to save data to firestore
const customerEntry = async (fname, lname, email, phone) => {
    const newCustomerDoc = db.collection("customers").doc(phone);
    let CustomerDoc = await newCustomerDoc.get();
    if (!CustomerDoc.exists) {
        newCustomerDoc.set({
            fname: fname,
            lname: lname,
            email: email,
            phone: phone,
        });
        document.getElementById("join-giggle-form").style.display = "none";
        document.getElementById("registered-container").style.display = "block";
    } else {
        console.log("Already exists");
        document.getElementById("member-exists-error").style.display = "block";
    }
};

const HomeJoin = () => {
    return (
        <div className="row fs-5">
            <div className="col-xs col-sm-6 join-text ">
                <div className="join-heading row fs-1">
                    <div className="col text-center">Join the Giggle</div>
                </div>
                <div className="join-details text-start">
                    Sign up today to get exclusive access to limited time offers and member-only
                    events.
                </div>
            </div>
            <div className="join-form text-center col-xs col-sm-6">
                <div id="registered-container">
                    <div className="registered-heading">Thanks for signing up.</div>
                    <div className="registered-content">
                        We'll keep you informed about promotions and deals in our stores.
                    </div>
                </div>
                <form className="row" id="join-giggle-form">
                    <div className="mb-3 col-xs col-sm-6">
                        <input
                            type="text"
                            id="fname"
                            className="form-control"
                            placeholder="First Name"
                            aria-label="First Name"
                            aria-describedby="basic-addon1"
                        />
                        <div className="join-the-giggle-form-errors" id="fname-error">
                            First name cannot be blank.
                        </div>
                    </div>
                    <div className="mb-3 col-xs col-sm-6">
                        <input
                            type="text"
                            id="lname"
                            className="form-control"
                            placeholder="Last Name"
                            aria-label="Last Name"
                            aria-describedby="basic-addon1"
                        />
                        <div id="lname-error" className="join-the-giggle-form-errors">
                            Last name cannot be blank.
                        </div>
                    </div>
                    <div className="mb-3 col-xs col-sm-6">
                        <input
                            type="email"
                            id="email"
                            className="form-control"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="basic-addon1"
                        />
                        <div id="email-error" className="join-the-giggle-form-errors">
                            Email cannot be blank.
                        </div>
                    </div>
                    <div className="mb-3 col-xs col-sm-6">
                        <input
                            type="number"
                            id="phone"
                            className="form-control"
                            placeholder="Phone Number"
                            aria-label="Phone Number"
                            aria-describedby="basic-addon1"
                        />
                        <div id="phone-error" className="join-the-giggle-form-errors">
                            Phone number is required.
                        </div>
                    </div>
                    <div className="join-button-container">
                        <div id="member-exists-error" className="join-the-giggle-form-errors">
                            You are already a giggle member.
                        </div>
                        <div className="join-the-giggle-submitted"></div>
                        <button
                            type="submit"
                            className="btn join-button"
                            data-bs-dismiss="modal"
                            onClick={submitForm}
                        >
                            Join
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default HomeJoin;
