import "./ContactUs.css";
import ContactForm from "./ContactForm";

// import phone from "../media/contact-phone.jpg";
// import email from "../media/contact-email.jpg";

const ContactUs = () => {
    return (
        <div className="contact-us-container">
            <div className="contact-us-elements">
                {/* <div className="contact-us-heading">Contact Us</div> */}
                <div className="contact-us-element contact-us-element0">
                    <div>
                        <div className="contact-us-header">We are here for you!</div>
                        <div className="contact-us-header2">
                            Connect with us through your preferred communication channel anytime and
                            let our team serve you.
                        </div>
                    </div>
                </div>
                <div className="contact-us-element contact-us-element1 text-center">
                    <div className="contact-us-card">
                        <a href="tel:+17053785323" className="link">
                            <div className="contact-call-icon contact-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    className="bi bi-telephone-outbound-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                                    />
                                </svg>
                            </div>
                            <div className="contact-call-text contact-text">Call us</div>
                        </a>
                    </div>

                    <div className="contact-us-card">
                        <a href="mailto: litup@giggleleaf.ca" className="link">
                            <div className="contact-email-icon contact-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    fill="currentColor"
                                    className="bi bi-envelope-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                                </svg>
                            </div>
                            <div className="contact-email-text contact-text">Send an Email</div>
                        </a>
                    </div>
                </div>
                <div className="contact-us-element contact-us-element2 text-center">
                    <div className="contact-us-header">Send us a message!</div>
                    <div className="contact-us-form">
                        <ContactForm />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
