import { useEffect } from "react";
const Store1 = () => {
    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://api.dutchie.com/api/v2/embedded-menu/60989515807b3600b46a0e27.js";
        script.id = "dutchie--embed__script";
        script.async = true;

        console.log(script);
        document.getElementById("dutchie").appendChild(script);

        return () => {
            document.getElementById("dutchie").removeChild(script);
        };
    }, []);

    return (
        // <div id="dutchie-shop">
        //     <div>
        //         <iframe
        //             className="dutchie--iframe"
        //             id="dutchie--embed__iframe"
        //             frameborder="0"
        //             title="Dutchie Menu"
        //             src="https://v3.dutchie.com/embedded-menu/giggle-leaf/?"
        //             allow="camera;microphone;geolocation;gyroscope"
        //         ></iframe>
        //     </div>
        // </div>
        <div id="dutchie"></div>
    );
};

export default Store1;
