import { useState } from "react";
import { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { closeNav } from "./functions.js";

import "./App.css";
import Home from "./components/Home.js";
import Age from "./components/Age.js";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import Jobs from "./components/Jobs.js";
import ContactUs from "./components/ContactUs.js";
import About from "./components/About.js";
import Events from "./components/Events.js";
import Stores from "./components/Stores.js";
import OrderButton from "./components/OrderButton.js";
import Store1 from "./components/Store1.js";

// import useScript from "./components/useScript";
// import { Helmet } from "react-helmet";

function App() {
    useEffect(() => {
        if (validated) {
            var divRestOfPage = document.getElementById("rest-of-page");
            divRestOfPage.onclick = closeNav;
        }
    }, []);

    // useScript("https://dutchie.com/api/v2/embedded-menu/60989515807b3600b46a0e27.js");
    // Get cookie
    const getCookie = (key) => {
        var cookieArr = document.cookie.split("; ");
        for (var i = 0; i < cookieArr.length; i++) {
            const item = cookieArr[i].split("=");
            const storedKey = item[0];
            if (key === storedKey) {
                const storedValue = item[1];
                const value = decodeURIComponent(storedValue);
                return value;
            }
        }
        return "";
    };
    const [validated, setValidated] = useState(false);
    //Check cookie and render App accordingly
    if (!validated) {
        var ageVerified = getCookie("age");
    }

    if (ageVerified === "ok" || ageVerified === "forget") {
        setValidated(true);
    }

    if (validated)
        return (
            // <div className="app">
            //     <Home />
            // </div>
            <BrowserRouter>
                <Switch>
                    <Route path="/shop">
                        <Navbar />
                        <div id="rest-of-page" onClick={closeNav}>
                            <div id="center-pages">
                                <Stores />
                            </div>
                            <Footer />
                        </div>
                        {/* <div id="rest-of-page">
                            <div id="center-pages">
                                <div id="dutchie-shop">
                                <div>
                                    <iframe className="dutchie--iframe" id="dutchie--embed__iframe" frameborder="0" title="Dutchie Menu" src="https://v3.dutchie.com/embedded-menu/giggle-leaf/?" allow="camera;microphone;geolocation;gyroscope"></iframe>
                                </div>
                                </div>
                            </div>
                        </div> */}
                    </Route>
                    <Route path="/store">
                        <Navbar />
                        <div id="rest-of-page">
                            <div id="center-pages">
                                <Store1 />
                                {/* <div id="dutchie-shop">
                                    <div>
                                        <iframe
                                            className="dutchie--iframe"
                                            id="dutchie--embed__iframe"
                                            frameborder="0"
                                            title="Dutchie Menu"
                                            src="https://v3.dutchie.com/embedded-menu/giggle-leaf/?"
                                            allow="camera;microphone;geolocation;gyroscope"
                                        ></iframe>
                                    </div>
                                </div> */}
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route path="/events">
                        <Navbar />
                        <OrderButton />
                        <div id="rest-of-page">
                            <div id="center-pages">
                                <Events />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route path="/about">
                        <Navbar />
                        <OrderButton />
                        <div id="rest-of-page">
                            <div id="center-pages">
                                <About />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route path="/jobs">
                        <Navbar />
                        <OrderButton />
                        <div id="rest-of-page">
                            <div id="center-pages">
                                <Jobs />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route path="/contact">
                        <Navbar />
                        <OrderButton />
                        <div id="rest-of-page">
                            <div id="center-pages">
                                <ContactUs />
                            </div>
                            <Footer />
                        </div>
                    </Route>
                    <Route path="/">
                        <OrderButton />
                        <div id="center-pages">
                            <Home />
                        </div>
                        <Footer />
                    </Route>
                </Switch>
                {/* <div className="footer-component"> */}
                {/* </div> */}
            </BrowserRouter>
        );
    else
        return (
            <BrowserRouter>
                <Age validated={validated} changevalidate={(val) => setValidated(val)} />
            </BrowserRouter>
        );
}

export default App;
