import logo from "../media/logowhite.png";
import blogo from "../media/logo.png";
const HomeLogo = () => {
    var x = window.matchMedia("(max-width: 575px)");
    if (x.matches) {
        return <img src={blogo} width="100%" alt="GiggleLeaf" />;
    } else {
        return <img src={logo} width="100%" alt="GiggleLeaf" />;
    }
};

export default HomeLogo;
