import "./ShopCategory.css";

import flower from "../media/icons/Flower.png";
import prerolls from "../media/icons/Prerolls.png";
import vaporizers from "../media/icons/Vaporizers.png";
import concentrates from "../media/icons/Concentrates.png";
import edibles from "../media/icons/Edibles.png";
import topicals from "../media/icons/Topicals.png";
import seeds from "../media/icons/Seeds.png";
import accessories from "../media/icons/Accessories.png";
// import flower from "../media/icons/flower.png";
// import flower from "../media/icons/flower.png";

const ShopCategory = () => {
    return (
        <div className="shop-category-container">
            <div className="shop-category-heading">
                {/* <div className="shop-category-headingA">Choose your Recr/.eation</div> */}
                <div className="shop-category-headingA">Shop by Consumption</div>
            </div>
            <div className="shop-categories">
                <a href="/store?dtche%5Bcategory%5D=flower" className="category-card">
                    <div className="shop-category-icon">
                        <img src={flower} alt="Flower" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Dried Flower</div>
                    <div className="shop-category-description-container">
                        <div className="description-tags">
                            <div className="description-kickin ">Kick In: 2 to 10 minutes.</div>
                            <div className="description-duration">Duration: up to 6 hours.</div>
                        </div>

                        <div className="description-main">
                            The Flower also known as the bud or nug is the most popular form of
                            cannabis. It is typically smoked using a pipe or bong or broken up with
                            a grinder and rolled it into a joint or blunt.
                        </div>
                    </div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=pre-rolls" className="category-card">
                    <div className="shop-category-icon">
                        <img src={prerolls} alt="Pre-Rolls" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Pre-Rolls</div>
                    <div className="shop-category-description-container">
                        <div className="description-tags">
                            <div className="description-kickin ">Kick In: 2 to 10 minutes.</div>
                            <div className="description-duration">Duration: up to 6 hours.</div>
                        </div>

                        <div className="description-main">
                            Pre-rolls are ready to go, expertly rolled joints which eliminate the
                            hassle of grinding, rolling, and sealing the cannabis flower. Pre-rolled
                            joints come in strain-specific flavors and, in different weights and
                            sizes.
                        </div>
                    </div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=vaporizers" className="category-card">
                    <div className="shop-category-icon">
                        <img src={vaporizers} alt="Vaporizers" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Vaporizers</div>
                    <div className="shop-category-description-container">
                        <div className="description-tags">
                            <div className="description-kickin ">Kick In: 2 to 10 minutes.</div>
                            <div className="description-duration">Duration: up to 6 hours.</div>
                        </div>

                        <div className="description-main">
                            Cannabis Vapes also known as vaporizers, vape pens or weed pens heat
                            cannabis flower or concentrates to a temperature that turns the active
                            cannabinoids and terpenes into inhalable vapor.
                        </div>
                    </div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=concentrates" className="category-card">
                    <div className="shop-category-icon">
                        <img
                            src={concentrates}
                            alt="Concentrates"
                            className="shop-category-icon-img"
                        />
                    </div>
                    <div className="shop-category-text">Concentrates</div>
                    <div className="shop-category-description-container">
                        <div className="description-tags">
                            <div className="description-kickin ">Kick In: 30 to 120 minutes.</div>
                            <div className="description-duration">Duration: up to 12 hours.</div>
                        </div>

                        <div className="description-main">
                            Concentrates are made by extracting cannabinoids, such as THC and CBD,
                            from the cannabis plant and putting the extracts into a carrier
                            substance like oil.
                        </div>
                    </div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=edibles" className="category-card">
                    <div className="shop-category-icon">
                        <img src={edibles} alt="Edibles" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Edibles</div>
                    <div className="shop-category-description-container">
                        <div className="description-tags">
                            <div className="description-kickin ">Kick In: 30 to 120 minutes.</div>
                            <div className="description-duration">Duration: up to 12 hours.</div>
                        </div>

                        <div className="description-main">
                            Cannabis Edibles are delicious treats like chocolates, cookies and
                            brownies that are infused with cannabis extracts which contain active
                            ingredients such as tetra hydrocannabinol and cannabidiol.
                        </div>
                    </div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=topicals" className="category-card">
                    <div className="shop-category-icon">
                        <img src={topicals} alt="Topicals" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Topicals</div>
                    <div className="shop-category-description-container">
                        <div className="description-tags">
                            <div className="description-kickin ">Kick In: 30 to 120 minutes.</div>
                            <div className="description-duration">Duration: up to 12 hours.</div>
                        </div>

                        <div className="description-main">
                            Cannabis Topicals are products such as lotions and oils that have been
                            infused with cannabis extracts and meant to be applied to the external
                            body surface which includes hair and skin.
                        </div>
                    </div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=seeds" className="category-card">
                    <div className="shop-category-icon">
                        <img src={seeds} alt="Seeds" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Seeds</div>
                    <div className="shop-category-description-container">
                        {/* <div className="description-tags">
                            <div className="description-kickin ">Kick In: 2 to 10 minutes.</div>
                            <div className="description-duration">Duration: up to 6 hours.</div>
                        </div> */}

                        <div className="description-main description-main-accessories">
                            With cannabis seeds you can cultivate your own plant. So, it is possible
                            to grow your own buds. All our seeds are organically grown and tested
                            regularly for the highest quality.
                        </div>
                    </div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=accessories" className="category-card">
                    <div className="shop-category-icon">
                        <img
                            src={accessories}
                            alt="Accessories"
                            className="shop-category-icon-img"
                        />
                    </div>
                    <div className="shop-category-text">Accessories</div>
                    <div className="shop-category-description-container">
                        {/* <div className="description-tags">
                            <div className="description-kickin ">Kick In: 2 to 10 minutes.</div>
                            <div className="description-duration">Duration: up to 6 hours.</div>
                        </div> */}

                        <div className="description-main description-main-accessories">
                            Cannabis Accessories include rolling papers or wraps, holders, pipes,
                            water pipes, bongs and any other items that can be used in the
                            consumption or production of cannabis.
                        </div>
                    </div>
                </a>
                {/* <a href="/store?dtche%5Bcategory%5D=Tropicals" className="category-card">
                    <div className="shop-category-icon">
                        <img src={flower} alt="Flower" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Tropicals</div>
                </a>
                <a href="/store?dtche%5Bcategory%5D=Seeds" className="category-card">
                    <div className="shop-category-icon">
                        <img src={flower} alt="Flower" className="shop-category-icon-img" />
                    </div>
                    <div className="shop-category-text">Seeds</div>
                </a> */}
            </div>
        </div>
    );
};

export default ShopCategory;
