import "./Budtender.css";

import JobForm from "./JobForm.js";

function Budtender() {
    return (
        <div className="budtender-container">
            <div className="apply-container ">
                <div className="position-absolute top-0 start-50 translate-middle-x apply-header-text">
                    Apply for Budtender
                </div>
                <a href="/jobs">
                    <div className="position-absolute top-0 end-0 close-button">&times;</div>
                </a>
            </div>
            <div className="details">
                <div className="desc">
                    Giggle Leaf recognizes that employees are at the core of our success and take
                    pride in a corporate culture that emphasizes inclusiveness, collaboration and
                    diversity. Our employees stem from a wide range of professional backgrounds;
                    each bringing their own unique skills and talents to the table, working together
                    to continue our incredible momentum of growth.
                </div>
                <div className="responsibilities">
                    The Responsibilities <br />
                    <ul>
                        <li>
                            Assists customers in the selection of merchandise, while providing
                            information about the product, materials, technology, and functionality.
                        </li>
                        <li>
                            Ensures Giggle Leaf’s customer service standards are upheld by listening
                            to customer’s wants and needs and providing product information when
                            required.
                        </li>
                        <li>
                            Maintains store appearance; assists in processing and replenishing
                            merchandise and assist with customer service inquiries.
                        </li>
                        <li>
                            Help to support and create a team environment by respecting co-workers.
                        </li>
                        <li>
                            Maintain superior levels of merchandising in the store, ensuring that
                            product displays are attractive, space efficient, and secure.
                        </li>
                        <li>
                            Assist the management team in the training and supervising of employees.
                            Handle transactions with 100% accuracy.
                        </li>
                        <li>
                            Comply with retail policies regarding inventory control, sales
                            procedures, etc.
                        </li>
                        <li>
                            Perform daily maintenance of the store and store fixtures to ensure
                            neatness is upheld.
                        </li>
                        <li>Enforce existing store standards in the absence of store manager.</li>
                        <li>
                            Ensure building security is upheld by proper use of alarm systems and
                            store security devices.
                        </li>
                        <li>
                            Ensure that store is opened and closed in accordance with hours of
                            business.
                        </li>
                    </ul>
                </div>
                <br />
                <div className="bring">
                    What You Bring <br />
                    <ul>
                        <li>Honed critical thinking and decision-making skills.</li>
                        <li>Cannabis knowledge and industry background.</li>
                        <li>The ability to work independently with little supervision.</li>
                        <li>Excellent listening and communication skills.</li>
                        <li>Customer service experience/skills.</li>
                        <li>Supports environment of genuine customer connection.</li>
                        <li>
                            Acts as an empowered team member, exercising decision-making skills to
                            enhance each customer’s experience.
                        </li>
                        <li>Takes ownership of individual awareness of product knowledge.</li>
                    </ul>
                </div>
                <br />
                <div className="qualifications">
                    Qualifications <br />
                    <ul>
                        <li>
                            At least 2 years’ experience in a retail environment Proven success in
                            offering an exceptional customer experience.
                        </li>
                        <li>
                            A passion for our product and changing the way the world sees cannabis.
                        </li>
                        <li>
                            You must be at least 19 years old and be able to pass a police
                            background check.
                        </li>
                        <li>You must be able to provide a copy of the certificate (CannSell).</li>
                        <li>
                            First Aid/CPR training an asset (must be able to provide current copy of
                            certificate).
                        </li>
                    </ul>
                </div>
                {/* <div className="apply-button-container">
                    <a href="/jobs" className="green-button">
                        Apply for Budtender
                    </a>
                </div> */}
                <div className="apply-container">
                    <button
                        type="button"
                        className="btn apply-button"
                        data-bs-toggle="modal"
                        data-bs-target="#jobform"
                    >
                        Apply for Budtender
                    </button>

                    <div
                        className="modal fade"
                        id="jobform"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <JobForm position="BudTender" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Budtender;
