import "./Jobs.css";
import jobsImage from "../media/jobs0.jpg";
import { Switch, Route } from "react-router-dom";
import Budtender from "./Budtender.js";
import Manager from "./Manager.js";

function Jobs() {
    /* Set the hieght of the side navigation to 100 and the switch the +/- signs */
    function openDesc(active) {
        if (active !== undefined) {
            document.getElementById(active + "d").style.lineHeight = "100%";
            // document.getElementById(active + "d").style.height = "100%";
            document.getElementById(active + "d").style.opacity = "100%";
            document.getElementById(active + "e").style.visibility = "hidden";
            document.getElementById(active + "c").style.visibility = "visible";
        }
    }

    /* Set the hieght of the side navigation to 0 and the switch the +/- signs*/
    function closeDesc(active) {
        if (active !== undefined) {
            document.getElementById(active + "d").style.lineHeight = "0%";
            // document.getElementById(active + "d").style.height = "1%";
            document.getElementById(active + "d").style.opacity = "0%";
            document.getElementById(active + "e").style.visibility = "visible";
            document.getElementById(active + "c").style.visibility = "hidden";
        }
    }

    return (
        <div className="jobs-container" id="main">
            <div className="banner position-relative">
                <div className="banner-text position-absolute top-50 start-50 translate-middle">
                    We're Hiring
                </div>
                <img className="img" src={jobsImage} alt="GiggleLeaf"></img>
            </div>
            <div className="positions-container">
                <div className="pos-heading">Positions</div>
                <div className="d-flex positions ">
                    <div
                        className="expand-button position-relative user-select-none"
                        onClick={() => {
                            // setActive("1");
                            // console.log("Active: " + active);
                            openDesc("1");
                        }}
                        id="1e"
                    >
                        +
                    </div>
                    <div
                        className="contract-button position-absolute user-select-none"
                        onClick={() => {
                            // setActive("1");
                            // console.log("Inactive: " + active);
                            closeDesc("1");
                        }}
                        id="1c"
                    >
                        -
                    </div>
                    <div className="position">BudTender</div>
                    <a href="/jobs/budtender">
                        <div className="apply-link">Details</div>
                    </a>
                </div>
                <ul className="job-details" id="1d">
                    <li>
                        In the role of <em>BUDTENDER</em>, you are responsible to assist store
                        management in reaching all store objectives.
                    </li>
                    <li>
                        To ensure the retail store maximizes its sales revenue through excellent
                        customer service, store maintenance, merchandising and handling product
                        shipments.
                    </li>
                </ul>
                {/* START OF EXTRA JOB LISTINGS FOR TESTING *
        <div className="d-flex positions ">
          <div
            className="expand-button position-relative user-select-none"
            onClick={() => {
              // setActive("1");
              // console.log("Active: " + active);
              openDesc("1");
            }}
            id="1e"
          >
            +
          </div>
          <div
            className="contract-button position-absolute user-select-none"
            onClick={() => {
              // setActive("1");
              // console.log("Inactive: " + active);
              closeDesc("1");
            }}
            id="1c"
          >
            -
          </div>
          <div className="position">BudTender</div>
          <a href="/jobs/budtender">
            <div className="apply-link">Details</div>
          </a>
        </div>
        <ul className="job-details" id="1d">
          <li>
            In the role of <em>BUDTENDER</em>, you are responsible to assist store management in reaching all store objectives.
          </li>
          <li>To ensure the retail store maximizes its sales revenue through excellent customer service, store maintenance, merchandising and handling product shipments.</li>
        </ul>

        <div className="d-flex positions ">
          <div
            className="expand-button position-relative user-select-none"
            onClick={() => {
              // setActive("1");
              // console.log("Active: " + active);
              openDesc("1");
            }}
            id="1e"
          >
            +
          </div>
          <div
            className="contract-button position-absolute user-select-none"
            onClick={() => {
              // setActive("1");
              // console.log("Inactive: " + active);
              closeDesc("1");
            }}
            id="1c"
          >
            -
          </div>
          <div className="position">BudTender</div>
          <a href="/jobs/budtender">
            <div className="apply-link">Details</div>
          </a>
        </div>
        <ul className="job-details" id="1d">
          <li>
            In the role of <em>BUDTENDER</em>, you are responsible to assist store management in reaching all store objectives.
          </li>
          <li>To ensure the retail store maximizes its sales revenue through excellent customer service, store maintenance, merchandising and handling product shipments.</li>
        </ul>

        {/* END OF EXTRA JOB LISTINGS FOR TESTING */}
                <div className="d-flex positions ">
                    <div
                        className="expand-button position-relative user-select-none"
                        onClick={() => {
                            // setActive("2");
                            // console.log("Active: " + active);
                            openDesc("2");
                        }}
                        id="2e"
                    >
                        +
                    </div>
                    <div
                        className="contract-button position-absolute user-select-none"
                        onClick={() => {
                            // setActive("2");
                            // console.log("Inactive: " + active);
                            closeDesc("2");
                        }}
                        id="2c"
                    >
                        -
                    </div>
                    <div className="position">Cashier</div>
                    <a href="/jobs/cashier">
                        <div className="apply-link">Details</div>
                    </a>
                </div>
                <div className="job-details" id="2d">
                    No vacant positions.
                </div>

                <div className="d-flex positions ">
                    <div
                        className="expand-button position-relative user-select-none"
                        onClick={() => {
                            // setActive("3");
                            // console.log("Active: " + active);
                            openDesc("3");
                        }}
                        id="3e"
                    >
                        +
                    </div>
                    <div
                        className="contract-button position-absolute user-select-none"
                        onClick={() => {
                            // setActive("3");
                            // console.log("Inactive: " + active);
                            closeDesc("3");
                        }}
                        id="3c"
                    >
                        -
                    </div>
                    <div className="position">Manager</div>
                    <a href="/jobs/manager">
                        <div className="apply-link">Details</div>
                    </a>
                </div>
                <div className="job-details" id="3d">
                    No vacant positions.
                </div>
            </div>
            <div className="position-relative">
                <Switch>
                    <Route path="/jobs/budtender">
                        <Budtender />
                    </Route>
                    <Route path="/jobs/cashier">
                        <div className="position-absolute top-50 start-50 translate-middle manager-container">
                            No cashier positions open right now.
                        </div>
                    </Route>
                    <Route path="/jobs/manager">
                        <Manager />
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
export default Jobs;
