import firebase from "firebase";
import "firebase/analytics";

// The web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA-aOOTX_Yd6L-Fe07Czb6w4yNI3kDsK_A",
    authDomain: "giggleleaf-10.firebaseapp.com",
    databaseURL: "https://giggleleaf-10-default-rtdb.firebaseio.com",
    projectId: "giggleleaf-10",
    storageBucket: "giggleleaf-10.appspot.com",
    messagingSenderId: "811908279706",
    appId: "1:811908279706:web:6b52a8532f4cc1cdda9e23",
    measurementId: "G-LTGSYDGXHQ",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const storage = firebaseApp.storage().ref();

export { db, storage };

// Initialize Analytics and get a reference to the service
const analytics = firebase.analytics();
// analytics.logEvent("App launched");
