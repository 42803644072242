import "./Stores.css";
import image3 from "../media/image3.jpg";
import image4 from "../media/image4.jpg";

const Stores = () => {
    return (
        <div className="stores-container">
            <div className="store-container container-fluid">
                <div className="stores-heading row fs-2 text-center">
                    <div className="col-xs-1 col-sm">Find a store close to you</div>
                </div>
                <div className="stores row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="store store-1 ">
                            <img src={image3} width="100%" alt="Parry Sound Store" />
                            <div className="store-details-container">
                                <div className="store-row-1 row">
                                    <div className="store-name col-12 col-sm-6">Parry Sound</div>
                                    <div className="store-link col-12 col-sm-6">
                                        <a href="/store">
                                            <button className="btn spopup-button store-button">
                                                Pick-up
                                            </button>
                                        </a>
                                        {/* <a href="" className="disabled"> */}
                                        <button className="btn spopup-button disabled store-button">
                                            Delivery
                                        </button>
                                        {/* </a> */}
                                    </div>
                                </div>
                                <div className="store-row-2 row">
                                    <a
                                        href="https://goo.gl/maps/BJ6R2FJE9kRMt52G9"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="stores-links col-12 col-sm-6"
                                    >
                                        <div className="address-container">
                                            {/* <div className="row"> */}
                                            <div className="address-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-geo-alt-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                                </svg>
                                            </div>
                                            <div className="address-text">
                                                <div className="row">
                                                    <div className="col">1 Horseshoe Lake Road</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col">Parry Sound ON P2A2W8</div>
                                                </div>
                                            </div>
                                            {/* </div> */}
                                        </div>
                                    </a>
                                    <a
                                        href="tel:+17053785323"
                                        className="stores-links col-12 col-sm-6"
                                    >
                                        <div className="phone-container">
                                            <div className="phone-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-telephone-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                                                    />
                                                </svg>
                                            </div>
                                            <div className="phone-text">(705)378-LEAF(5323)</div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            {/* <div className="review-container parrysound-reviews">
                                <div className="review">
                                    <a
                                        href="https://www.leafly.ca/cannabis-store/giggle-leaf"
                                        title="Dispensary Reviews"
                                        target="_blank"
                                    >
                                        <img
                                            src="https://www.leafly.com/badge/2x/giggle-leaf.png"
                                            width="212"
                                        />
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <div className="store store-1 ">
                            <img src={image4} width="100%" alt="Parry Sound Store" />
                            <div className="store-details-container container-fluid">
                                <div className="store-row-1 row">
                                    <div className="store-details col-12 col-sm-6">Etobicoke</div>
                                    <div className="store-link col-12 col-sm-6 fs-4">
                                        Coming Soon.
                                        {/* <a href="/store">Visit this Store</a> */}
                                    </div>
                                </div>
                                {/* <div className="store-row-2 row">
                                    <div className="address-container col-12 col-sm-6">
                                        <div className="address-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-geo-alt-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                            </svg>
                                        </div>
                                        <div className="address-text">
                                            <div className="row">
                                                <div className="col">1 Horseshoe Lake Road</div>
                                            </div>
                                            <div className="row">
                                                <div className="col">Parry Sound ON P2A2W8</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="phone-container col-12 col-sm-6">
                                        <div className="phone-icon">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-telephone-fill"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                                                />
                                            </svg>
                                        </div>
                                        <div className="phone-text">(705)378-LEAF(5323)</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-xs-12 col-sm-6">
                        <div className="store store-3">
                            <img
                                src="https://cdn.accentuate.io/51185582179/7779261087843/Cannabis-Store-%5BToronto-Bloorcourt%5D---Dutch-Love-v1614628185569.jpg"
                                width="100%"
                                alt="Parry Sound Store"
                            />
                            <div className="store-details d-flex row justify-content-end">
                                <div className="city col text-left">City</div>
                                <div className="store-name col text-end">Store Name</div>
                            </div>
                            <div className="store-links"></div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Stores;
