import "./Manager.css";

function Manager() {
    return (
        <div className="position-absolute top-50 start-50 translate-middle manager-container">
            No manager positions open right now.
        </div>
    );
}
export default Manager;
