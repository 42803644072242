import "./JobForm.css";
import { useState } from "react";
import { db, storage } from "../firebase.js";
import { init as emailJSinit, send as emailJSsend } from "emailjs-com";
emailJSinit("user_GLqjlpGUa5WHx459UJmgq");

const JobForm = ({ position }) => {
    var [filesValid, setFilesValid] = useState(false);
    const [resume_file, setResumeFile] = useState("");
    const [cover_file, setCoverFile] = useState("");

    const fileSizeCheck = (e) => {
        let errorID = e.target.id + "-error";
        if (e.target.files[0].size > 5242880) {
            document.getElementById(errorID).innerHTML = "File size exceeded 5MB";
            document.getElementById(errorID).style.display = "block";
            // e.target.value = "";
        } else {
            document.getElementById(errorID).innerHTML = "Error";
            document.getElementById(errorID).style.display = "none";
            setFilesValid(true);
        }
    };

    // Function to get data from the html form
    function submitJobForm(e) {
        e.preventDefault();
        console.log("Form Submitted");
        let fullname = document.getElementById("fullname").value;
        let email = document.getElementById("email").value;
        let phone = document.getElementById("number").value;
        let resume = document.getElementById("resume-file").value;
        let cover = document.getElementById("cover-file").value;

        // Errors
        let name_error = false;
        let number_error = false;
        let email_error = false;
        let resume_error = false;

        document.getElementById("fullname-error").style.display = "none";
        document.getElementById("email-error").style.display = "none";
        document.getElementById("number-error").style.display = "none";
        document.getElementById("resume-file-error").style.display = "none";
        document.getElementById("cover-file-error").style.display = "none";

        if (true) {
            if (fullname === "") {
                console.log("First name blank");
                document.getElementById("fullname-error").innerHTML = "Please enter your name.";
                document.getElementById("fullname-error").style.display = "block";
                name_error = true;
            } else {
                document.getElementById("fullname-error").style.display = "none";
                name_error = false;
            }
            if (phone === "") {
                console.log("Phone number blank");
                document.getElementById("number-error").innerHTML =
                    "Please enter your contact number.";
                document.getElementById("number-error").style.display = "block";
                number_error = true;
            } else {
                document.getElementById("number-error").style.display = "none";
                number_error = false;
            }
            if (email !== "") {
                document.getElementById("email-error").style.display = "none";
                email_error = true;
            } else {
                email_error = false;
            }
            if (resume === "") {
                console.log("Resume blank");
                document.getElementById("resume-file-error").innerHTML =
                    "Please attach your resume.";
                document.getElementById("resume-file-error").style.display = "block";
                resume_error = true;
            } else {
                document.getElementById("resume-file-error").style.display = "none";
                resume_error = false;
            }
            if (cover !== "") {
                document.getElementById("cover-file-error").style.display = "none";
            }
        }

        // if (fname != "" && lname != "" && email != "" && phone != "")
        //     console.log(fname + " " + lname + " " + email + " " + phone);

        // Save User's input
        if (!name_error && !number_error && !resume_error) {
            jobEntry(fullname, email, phone);
        } else {
            console.log("Some error occured ! Check above messages for more details.");
        }

        return;
    }

    // Function to save data to firestore
    const jobEntry = async (fullname, email, phone) => {
        let resumeURL = "";
        let coverURL = "";

        const newJobDoc = db.collection("job-applications").doc(phone);
        let JobDoc = await newJobDoc.get();

        if (!JobDoc.exists) {
            if (filesValid) {
                if (resume_file) {
                    const resumeRef = storage.child("resumes/" + fullname + " Resume");
                    await resumeRef.put(resume_file).then(async () => {
                        console.log("Resume uploaded");
                        document.getElementById("resume-file-error").innerHTML = "Upload complete.";
                        document.getElementById("resume-file-error").style.display = "block";
                        document.getElementById("resume-file-error").style.color = "#426b2e";
                        resumeURL = await resumeRef.getDownloadURL();
                    });
                } else {
                    document.getElementById("resume-file-error").innerHTML = "Error";
                    document.getElementById("resume-file-error").style.display = "none";
                }
                if (cover_file) {
                    const coverRef = storage.child("resumes/" + fullname + " Cover");
                    await coverRef.put(cover_file).then(async () => {
                        console.log("Cover uploaded");
                        document.getElementById("cover-file-error").innerHTML = "Upload complete.";
                        document.getElementById("cover-file-error").style.display = "block";
                        document.getElementById("cover-file-error").style.color = "#426b2e";
                        coverURL = await coverRef.getDownloadURL();
                    });
                } else {
                    document.getElementById("cover-file-error").innerHTML = "Error";
                    document.getElementById("cover-file-error").style.display = "none";
                }
            } else {
                document.getElementById("cover-file-error").innerHTML = "Error in uploading files.";
                document.getElementById("cover-file-error").style.display = "block";
                return;
            }

            console.log("Resume added to Database");
            console.log("Cover added to Database");
            newJobDoc.set({
                fullname: fullname,
                email: email,
                phone: phone,
                resume: resumeURL,
                cover: coverURL,
            });

            emailJSsend("jidodalgmail", "gigglejobs", {
                position: position,
                app_name: fullname,
                app_email: email,
                app_number: phone,
                app_resume: resumeURL,
                app_cover: coverURL,
            });

            document.getElementById("cover-file-error").innerHTML = "Thank you for applying.";
            document.getElementById("cover-file-error").style.display = "block";
            document.getElementById("cover-file-error").style.color = "#426b2e";
            document.getElementById("cover-file-error").style.fontSize = "1rem";
        } else {
            console.log("Already exists");
            document.getElementById("cover-file-error").innerHTML = "We already have your resume.";
            document.getElementById("cover-file-error").style.display = "block";
            document.getElementById("cover-file-error").style.color = "#426b2e";
            document.getElementById("cover-file-error").style.fontSize = "1.2rem";
            document.getElementById("cover-file-error").style.paddingTop = "1.2rem";
        }
    };
    return (
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        {position}
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <form className="row g-3">
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Fullname"
                                aria-label="Fullname"
                                aria-describedby="basic-addon1"
                                id="fullname"
                            />
                            <label className="error-sub-text" id="fullname-error">
                                Error
                            </label>
                        </div>
                        <div className="mb-3">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Email (Optional)"
                                aria-label="Email"
                                aria-describedby="basic-addon1"
                                id="email"
                            />
                            <label className="error-sub-text" id="email-error">
                                Error
                            </label>
                        </div>
                        <div className="mb-3">
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Contact Number"
                                aria-label="Number"
                                aria-describedby="basic-addon1"
                                id="number"
                            />
                            <label className="error-sub-text" id="number-error">
                                Error
                            </label>
                        </div>
                        <div className="mb-3">
                            <label for="formFileSm" className="form-label">
                                Resume
                            </label>
                            <br />
                            <label className="sub-text">
                                Supported upload formats: 5MB max in .PDF, .JPG or .DOCX
                            </label>
                            <input
                                className="form-control form-control-sm"
                                id="resume-file"
                                type="file"
                                accept=".pdf, .jpg, .jpeg, .png, .docx, .doc"
                                onChange={(e) => {
                                    fileSizeCheck(e);
                                    setResumeFile(e.target.files[0]);
                                }}
                            />
                            <label className="error-sub-text" id="resume-file-error">
                                Error
                            </label>
                        </div>
                        <div className="mb-3">
                            <label for="formFileSm" className="form-label">
                                Cover letter (optional)
                            </label>
                            <input
                                className="form-control form-control-sm"
                                id="cover-file"
                                type="file"
                                accept=".pdf, .jpg, .jpeg, .png, .docx, .doc"
                                onChange={(e) => {
                                    fileSizeCheck(e);
                                    setCoverFile(e.target.files[0]);
                                }}
                            />
                            <label className="error-sub-text" id="cover-file-error">
                                Error
                            </label>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="button" className="btn job-submit-button" onClick={submitJobForm}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default JobForm;
