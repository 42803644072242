export function AgeCalculator(birthday) {
    const curr = new Date();

    const currtime = [curr.getDate(), curr.getMonth() + 1, curr.getFullYear()];

    const cur = currtime[0] / 365 + currtime[1] / 12 + currtime[2];
    const bd = birthday[0] / 365 + birthday[1] / 12 + birthday[2] / 1;
    // console.log("Cur: " + cur);
    // console.log("Btd: " + bd);
    return cur - bd;
}
