import "./Navbar.css";
import logo from "../media/logo.png";
import small from "../media/small-logo.png";

import Sidebar from "./Sidebar.js";

import { openNav } from "../functions.js";

const Navbar = () => {
    return (
        <div className="navbar-container">
            <div className="navbar-inside" id="main">
                <div className="rightnavbut navbut">
                    <div id="menu" onClick={openNav}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="35"
                            fill="currentColor"
                            className="bi bi-list"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fillRule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                            />
                        </svg>
                    </div>
                </div>

                <a className="homelink" href="/">
                    <img src={logo} id="home-image" alt="GiggleLeaf"></img>
                </a>

                <div className="leftnavbut navbut">
                    <a id="navbar-small-logo" href="/shop">
                        <img src={small} height="100%" alt="GiggleLeaf"></img>
                    </a>
                </div>
            </div>
            <Sidebar />
        </div>
    );
};
export default Navbar;
