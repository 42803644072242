import "./Age.css";
import { useState } from "react";
import { AgeCalculator } from "./AgeCalculator.js";
import logo from "../media/logo.png";
import smallogo from "../media/small-logo.png";

const Age = ({ validated, changevalidate }) => {
    var store = false;
    const [invalidAge, setInvalidAge] = useState(false);
    const [date, setDate] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();

    const onSubmit = () => {
        //Check if the user is 18 and above
        var birthday = [date, month, year];
        const currYear = new Date().getFullYear();
        if (currYear - year <= 17) {
            setInvalidAge(true);
            console.log("Direct Nikal");
        }
        if (date < 1 || date > 31) {
            document.getElementById("invalid-date").style.display = "block";
            document.getElementById("invalid-date").innerHTML =
                "Please enter date between 1 and 31.";
            return;
        } else if (month < 1 || month > 12) {
            document.getElementById("invalid-date").style.display = "block";
            document.getElementById("invalid-date").innerHTML =
                "Please enter month between 1 and 12.";
            return;
        } else if (year < 1900) {
            document.getElementById("invalid-date").style.display = "block";
            document.getElementById("invalid-date").innerHTML = "Please enter a valid year.";
            return;
        } else {
            document.getElementById("invalid-date").style.display = "none";
        }

        var age = AgeCalculator(birthday);

        // console.log("\nBirthday:" + birthday + "\nAge: " + age);
        if (age >= 18) {
            setInvalidAge(false);
            if (store) {
                // Getting time
                const expire = new Date();
                expire.setTime(expire.getTime() + 2592000000);
                var expireString = expire.toUTCString();
                //Set cookie to expire in 30 days
                console.log("Remembering till " + expireString);
                document.cookie = document.cookie = `age=ok; expires=${expireString}; path=/`;
            } else {
                //Set cookie to expire after session ends
                console.log("Not Remembering");
                document.cookie = document.cookie = `age=forget; path=/`;
            }
            // Continue to Main page
            changevalidate(true);
            //Stay on this page
            // changevalidate(false);
        } else {
            setInvalidAge(true);
            changevalidate(false);
        }
    };

    function movetoNext(current, nextFieldID) {
        if (current.value.length >= current.maxLength) {
            document.getElementById(nextFieldID).focus();
        }
    }

    return (
        // Test(test) test_test
        <div className="age-container">
            <div className="age">
                <div className="g-logo-container">
                    <img src={smallogo} alt="GiggleLeaf" className="g-logo" />
                </div>
                <div className="back">
                    <div className="welcome">
                        Welcome to
                        <img src={logo} alt="GiggleLeaf" className="welcome-logo" />
                    </div>
                    <div className="birthday">
                        <div className="alabel">We only sell to adults age 19 years or older.</div>
                        <label className="blabel">Please enter your birthday to continue</label>
                        <div className="innput">
                            <input
                                type="number"
                                id="date"
                                className="date"
                                placeholder="DD"
                                min="1"
                                max="31"
                                maxlength="2"
                                onKeyUp={(e) => movetoNext(e.target, "month")}
                                onChange={(e) => setDate(e.target.value)}
                            />
                            <input
                                type="number"
                                id="month"
                                className="month"
                                placeholder="MM"
                                min="1"
                                max="12"
                                maxlength="2"
                                onKeyUp={(e) => movetoNext(e.target, "year")}
                                onChange={(e) => setMonth(e.target.value)}
                            />
                            <input
                                type="number"
                                id="year"
                                className="year"
                                placeholder="YYYY"
                                min="1900"
                                max="2021"
                                maxlength="4"
                                onChange={(e) => setYear(e.target.value)}
                            />
                        </div>
                        <div className="remContainer">
                            <input
                                className="remChkinv"
                                type="checkbox"
                                id="remember"
                                name="Remember for 30 days"
                                onChange={(e) => {
                                    store = e.target.checked;
                                }}
                            />
                            <label className="remChk" for="remember"></label>
                            <div className="remLabels">
                                <label for="remember" className="remA">
                                    Remember me for 30 days
                                </label>
                                <label for="remember" className="remB">
                                    I confirm that this is not a shared device
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="error">
                        {invalidAge ? (
                            <div>You must be of legal age to enter this site.</div>
                        ) : null}
                        <div id="invalid-date">Invalid Date</div>
                    </div>
                    <div className="confirm-age">Yes, I am 19 years or older.</div>
                    <div className="submit">
                        <button type="submit" onClick={onSubmit} className="submitBtn">
                            ENTER
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Age;
