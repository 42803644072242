import "./About.css";

import about1 from "../media/about-1.jpg";
import about2 from "../media/about-2.jpg";
import about3 from "../media/about-3.jpg";

function About() {
    return (
        <div className="about-container">
            <div className="about-heading">
                <div className="about-heading-content">Roll over the stigma!</div>
            </div>
            <div className="about-content">
                <div className="about-image-container about-1">
                    <img src={about1} alt="About1" className="about-images im-fluid"></img>
                </div>
                <div className="about-text-right about-text">
                    At Giggle leaf we strive to change the public perception regarding cannabis.
                    Nature must have intended something wonderful while creating this incredible
                    plant. We believe that an informed and educated consumption of cannabis can help
                    you control and enhance your individual experience.
                </div>
            </div>
            <div className="about-content">
                <div className="about-image-container about-2">
                    <img src={about2} alt="About2" className="about-images image-fluid"></img>
                </div>
                <div className="about-text-left about-text">
                    We aspire to provide you with rich consumer experience through our diverse
                    products, gratifying service, compassionate culture, and informative approach.
                </div>
            </div>
            <div className="about-content">
                <div className="about-image-container about-3">
                    <img src={about3} alt="About3" className="about-images image-fluid"></img>
                </div>
                <div className="about-text-right about-text">
                    Giggle leaf tries to symbolize the essence of Aum (Om) which is creation,
                    preservation, and liberation. Aum (Om) epitomizes peace over stress, ecstasy
                    over agony and calm over chaos. So does our purpose at giggle leaf.
                </div>
            </div>
        </div>
    );
}
export default About;
