import "./Slideshow.css";

import image1 from "../media/image3.jpg";
import image2 from "../media/image4.jpg";
import image3 from "../media/image5.jpg";
import image4 from "../media/image6.jpg";

const Slideshow = () => {
    return (
        <div className="slideshow-container">
            <div className="shop-heading row fs-1">
                <div className="col text-center">Explore and Shop</div>
            </div>
            <div className="row shop-content text-center">
                <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={image1} className="carousel-image" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={image2} className="carousel-image" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={image3} className="carousel-image" alt="..." />
                        </div>
                        <div className="carousel-item">
                            <img src={image4} className="carousel-image" alt="..." />
                        </div>
                    </div>
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div className="container-fluid shop-bottom">
                <div className="row">
                    <div className="col-12 col-sm-8 text-start align-middle">
                        <div className="shop-text">Open Now!</div>
                    </div>
                    <div className="col-12 col-sm-4">
                        <a className="btn shop-button" href="/shop">
                            Find a store
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slideshow;
