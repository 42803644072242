import "./ContactForm.css";
// import { useState } from "react";
import { init as emailJSinit, send as emailJSsend } from "emailjs-com";
emailJSinit("user_GLqjlpGUa5WHx459UJmgq");

const ContactForm = () => {
    function submitContactForm(e) {
        e.preventDefault();

        let name_error = false;
        let email_error = false;
        let message_error = false;

        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let number = document.getElementById("number").value;
        let message = document.getElementById("message").value;

        // Send email to Giggleleaf contact.
        if (name === "") {
            console.log("First name blank");
            document.getElementById("name-error").innerHTML = "Please enter your name.";
            document.getElementById("name-error").style.display = "block";
            name_error = true;
        } else if (name_error) {
            document.getElementById("name-error").style.display = "none";
            name_error = false;
        }
        console.log(name_error);
        if (email === "") {
            console.log("Email address blank");
            document.getElementById("email-error").innerHTML = "Please enter your email address.";
            document.getElementById("email-error").style.display = "block";
            email_error = true;
        } else if (email_error) {
            document.getElementById("email-error").style.display = "none";
            email_error = false;
        }
        console.log(email_error);
        if (message === "") {
            console.log("message blank");
            document.getElementById("message-error").innerHTML = "Please enter your message.";
            document.getElementById("message-error").style.display = "block";
            message_error = true;
        } else if (message_error) {
            document.getElementById("message-error").style.display = "none";
            message_error = false;
        }
        console.log(message_error);

        if (!name_error && !email_error && !message_error) {
            console.log("Message from '" + name + "':");
            console.log(message + " " + email + " " + number);

            // Send Email
            emailJSsend("jidodalgmail", "gigglecontact", {
                name: name,
                email: email,
                number: number,
                message: message,
            });

            console.log("Message Sent.");
            document.getElementById("contact-form").style.visibility = "hidden";
            document.getElementById("contact-form-status").style.visibility = "visible";
        }
    }
    return (
        <div>
            <form className="row g-3" id="contact-form">
                <div className="contact-form-mb">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        aria-label="Name"
                        aria-describedby="basic-addon1"
                        id="name"
                    />
                    <label className="error-sub-text" id="name-error">
                        Error
                    </label>
                </div>
                <div className="contact-form-mb">
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="basic-addon1"
                        id="email"
                    />
                    <label className="error-sub-text" id="email-error">
                        Error
                    </label>
                </div>
                <div className="contact-form-mb">
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Contact Number (Optional)"
                        aria-label="Number"
                        aria-describedby="basic-addon1"
                        id="number"
                    />
                    <label className="error-sub-text" id="number-error">
                        Error
                    </label>
                </div>
                <div className="contact-form-mb contact-form-textarea-container">
                    <textarea
                        className="form-control contact-form-textarea"
                        placeholder="Your Message"
                        aria-label="Your Message"
                        aria-describedby="basic-addon1"
                        id="message"
                    />
                    <label className="error-sub-text" id="message-error">
                        Error
                    </label>
                </div>

                {/* File uploads */}

                {/* <div className="mb-3">
                    <label for="formFileSm" className="form-label">
                        Resume
                    </label>
                    <br />
                    <label className="sub-text">
                        Supported upload formats: 5MB max in .PDF, .JPG or .DOCX
                    </label>
                    <input
                        className="form-control form-control-sm"
                        id="resume-file"
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png, .docx, .doc"
                    />
                    <label className="error-sub-text" id="resume-file-error">
                        Error
                    </label>
                </div>
                <div className="mb-3">
                    <label for="formFileSm" className="form-label">
                        Cover letter (optional)
                    </label>
                    <input
                        className="form-control form-control-sm"
                        id="cover-file"
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png, .docx, .doc"
                    />
                    <label className="error-sub-text" id="cover-file-error">
                        Error
                    </label>
                </div> */}
                <div className="mb-3 contact-form-submit-button-container">
                    <button
                        type="submit"
                        className="btn contact-form-submit-button"
                        onClick={(e) => {
                            submitContactForm(e);
                        }}
                    >
                        Send
                    </button>
                </div>
            </form>
            <div className="contact-form-status" id="contact-form-status">
                Thank you for contacting us. Our team will get back to your shortly.
            </div>
        </div>
    );
};

export default ContactForm;
