export function closeNav() {
    var x = window.matchMedia("(max-width: 575px)");
    if (x.matches) {
        document.getElementById("mySidenav").style.right = "-100vw";
        document.getElementById("main").style.paddingRight = "0";
    } else {
        document.getElementById("mySidenav").style.right = "-25vw";
        document.getElementById("main").style.paddingRight = "0px";
        document.getElementById("menu").style.opacity = "100%";
        document.getElementById("rest-of-page").style.opacity = "100%";
    }
}

export function openNav() {
    /* Set the width of the side navigation to 250px and the left margin
    of the page content to 250px */
    var x = window.matchMedia("(max-width: 575px)");

    if (x.matches) {
        document.getElementById("mySidenav").style.right = "0";
        document.getElementById("main").style.paddingRight = "0";
    } else {
        document.getElementById("mySidenav").style.right = "0";
        document.getElementById("main").style.paddingRight = "25vw";
        document.getElementById("menu").style.opacity = "0%";
        document.getElementById("rest-of-page").style.opacity = "20%";
    }
}
