import "bootstrap/dist/css/bootstrap.min.css";
// import $ from "jquery";
// import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import App from "./App";
import ReactDOM from "react-dom";
// import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    // <BrowserRouter>
    <div className="app">
        <App />
    </div>,
    // </BrowserRouter>
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
