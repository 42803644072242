import "./OrderButton.css";

const OrderButton = () => {
    return (
        <div className="order-button-container" id="order-button">
            <button className="btn spopup-button order-button">
                <a href="/shop" style={{ color: "white" }} className="order-button-a">Order Online</a>
            </button>
        </div>
    );
};

export default OrderButton;
