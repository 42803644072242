import "./Events.css";
import jobsImage from "../media/jobs0.jpg";
// import { Switch, Route } from "react-router-dom";

function Jobs() {
  /* Set the hieght of the side navigation to 100 and the switch the +/- signs */
  return (
    <div className="jobs-container" id="main">
      <div className="banner position-relative">
        <div className="banner-text position-absolute top-50 start-50 translate-middle">Events</div>
        <img className="img" src={jobsImage} alt="GiggleLeaf"></img>
      </div>
      <div className="events-container">
        {/* <div className="pos-heading">Events</div> */}
        <div className="d-flex events ">No events. Come back later.</div>
      </div>
    </div>
  );
}
export default Jobs;
