import "./ShopPopup.css";

function spopupClose() {
    document.getElementById("shoppopup-component").style.left = "-500px";
    document.getElementById("order-button").style.opacity = "100%";
}

const ShopPopup = () => {
    return (
        <div className="spopup-container">
            <div id="spopup-close" onClick={spopupClose}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-x-lg "
                    viewBox="0 0 16 16"
                >
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                </svg>
            </div>
            <div className="spopup-icon-container row justify-content-center">
                <div className="spopup-icon col-8 ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="currentColor"
                        className=""
                        viewBox="0 0 16 16"
                    >
                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                </div>
            </div>
            <div className="spopup-text text-center row">
                Please place your order & your order will be ready.
            </div>
            <div className="position-relative row justify-content-center">
                <div className="col-10">
                    <button className="btn spopup-button">
                        <a href="/shop" className="spopup-button-a">Pick up or Delivery</a>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ShopPopup;
